import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import localForage from "localforage";
import {createRoot} from "react-dom/client";

import {rootProvider} from "./root_provider.jsx";

import "./style/boilerplate/main.css";
import "./style/boilerplate/normalize.css";

import "./style/common.scss";
import "./style/color.scss";
import "./style/tabbed.scss";

import "./style/control_servers.scss";
import "./style/calibrations.scss";
import "./style/sessions.scss";
import "./style/status.scss";

const ONE_HOUR_MS = 60 * 60 * 1000;

const agentMatches = window.navigator.userAgent.match(/Version\/([\d.]+)/);
global.OPERATING_SYSTEM = (agentMatches && agentMatches[1]) ? "iPadOS " + agentMatches[1].replace(/_/g, ".") : "Unknown";

dayjs.extend(LocalizedFormat);
dayjs.locale(window.navigator.language);

localForage.ready().then(() => {
  localForage.setDriver(localForage.INDEXEDDB);
  if (process.env.APP_NAME) {
    const metaTitle = Array.from(document.getElementsByTagName("meta")).find((e) => {
      return e.name === "apple-mobile-web-app-title";
    });
    metaTitle.content = process.env.APP_NAME;
    document.title = process.env.APP_NAME;
  }

  navigator.serviceWorker
  .register("service_worker.js", {scope: "./"})
  .then((r) => {
    console.debug("Index: Registered", r);
    setInterval(() => {
      console.debug("Index: Registration.update");
      r.update();
    }, ONE_HOUR_MS);
  });

  const root = createRoot(document.getElementById("app-container"));
  rootProvider(root, localForage);
});
