export function jobLastStatusChange(job) {
  switch (job.exit ?? job.status) {
    case "received": return job.recievedAt;
    case "queued":
    case "blocked":
    case "preparing": return job.queuedAt;
    case "executing": return job.startedAt;
    case "completing": return job.endedAt;
    default: return job.exitedAt;
  }
}
