import localForage from "localforage";
import React from "react";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {error: null};
  }

  static getDerivedStateFromError(error) {
    return {error};
  }

  componentDidCatch(error, errorInfo) {
  }

  render() {
    const handleReset = () => localForage.clear().then(() => window.location.reload());

    if (this.state.error) {
      return (
        <div class="error-boundary">
          <div className="error-label">Aqümen QWA encountered this error:</div>
          <div className="error-message">{this.state.error.message}</div>

          <button className="error-reset-button" onClick={handleReset}>Attempt Reset</button>
        </div>
      );
    }

    return this.props.children;
  }
}
