import Aqumen from "@aqumen/sdk";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {changeHandler} from "@minimum/utility";
import {InputValidatable} from "@minimum/utility";
import {submitStopper} from "@minimum/utility";

import {add} from "../../slice/systems_slice.js";

export function SystemNew(props) {
  const dispatch = useDispatch();

  const [system, setSystem] = useState({url: "", key: ""});
  const [addError, setAddError] = useState(null);
  const [verifying, setVerifying] = useState(false);

  const verifyError = (error) => {
    console.log("verifyError", {error});
    switch (error.code) {
      case "401": return "incorrect key";
      case "404": return "incorrect URL";
      case "418": return "incompatible API version";
      default:
        return (/network request/i.test(error.toString()))
          ? "unreachable"
          : error.message?.toLocaleLowerCase() ?? error.toString();
    }
  }

  const handleChange = changeHandler(setSystem, system);
  const handleSubmit = submitStopper(async () => {
    console.group("NewSystemForm.submit");
    try {
      setAddError(null);
      const cleaned = {url: system.url.replace(/(^https?:\/?\/?|^)/, "https://"), key: system.key};
      try {
        new URL(cleaned.url);
      } catch {
        setAddError("Unsupported system URL: " + cleaned.url);
        return;
      }
      console.debug("verifying", cleaned);
      setVerifying(true);
      try {
        // await Aqumen.Service.verify(cleaned.url, cleaned.key);
      } finally {
        setVerifying(false);
      }
      console.debug("adding", cleaned);
      dispatch(add(cleaned));
      setSystem({url: "", key: ""});
    } catch (e) {
      console.error("add failed", e);
      setAddError("system addition failed: " + verifyError(e));
    } finally {
      console.groupEnd();
    }
  });

  return (
    <div className={"system-new " + (props.className ?? "")}>

      <form className="system-new-form" onSubmit={handleSubmit} aria-labelledby="system-new-description" aria-describedby="system-new-error system-new-verifying">

        <div>
          <span className="url-input-prefix">
            https://
          </span>
          <InputValidatable name="url"
            aria-label={`URL of the system to add`}
            className="system-new-input-url"
            inputMode="url"
            invalidMessage="Enter a URL…"
            onChange={handleChange}
            placeholder="aqumen.example"
            required
            type="text"
            disabled={verifying}
            value={system.url}
          />
        </div>
        <div>
          <InputValidatable name="key"
            aria-label={`API key of the system to add`}
            className="system-new-input-key"
            invalidMessage="Enter the API key"
            onChange={handleChange}
            placeholder="abcd-efgh-ijkl…"
            required
            type="text"
            disabled={verifying}
            value={system.key}
          />

          <button type="submit" className="system-new-add" disabled={verifying}>
            Add System
          </button>

          <div className="system-new-verifying" id="system-new-verifying">
            {verifying && (
              <div className="system-new-verifying-message" role="alert">
                verifying…
              </div>
            )}
          </div>

          <div className="system-new-error" id="system-new-error">
            {addError && (
              <div className="system-new-error-message" role="alert">
                {addError}
              </div>
            )}
          </div>
        </div>
      </form>

    </div>
  );
}
