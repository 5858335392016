import {combineReducers} from "redux";
import {reducer as router} from "@minimum/router";
import {reducer as tabbed} from "@minimum/tabbed";

import {reducer as artifacts} from "./slice/artifacts_slice.js";
import {reducer as calibrations} from "./slice/calibrations_slice.js";
import {reducer as controlServers} from "./slice/control_servers_slice.js";
import {reducer as filters} from "./slice/filters_slice.js";
import {reducer as jobs} from "./slice/jobs_slice.js";
import {reducer as queues} from "./slice/queues_slice.js";
import {reducer as reservations} from "./slice/reservations_slice.js";
import {reducer as sessions} from "./slice/sessions_slice.js";
import {reducer as systems} from "./slice/systems_slice.js";
import {reducer as users} from "./slice/users_slice.js";

export const rootReducer = combineReducers({
  artifacts,
  calibrations,
  controlServers,
  filters,
  jobs,
  queues,
  reservations,
  router,
  sessions,
  systems,
  tabbed,
  users
});
