import _ from "lodash/fp";
import Aqumen from "@aqumen/sdk";
import React from "react";
import {useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {SvgIcon} from "../utility/svg_icon.jsx";
import {TimestampDisplay} from "../display/timestamp_display.jsx";
import {UserDisplay} from "../display/user_display.jsx";
import {flatSelector} from "../utility/flat_selector.js";

export function ControlServersSidebarTile(props) {
  const controlServer = props.value;

  const selectCalibrations = flatSelector("calibrations");
  const filterCalibrations =  createSelector(selectCalibrations, (all, f) => {
    return all.filter(c => c.controlServer.id === controlServer.id);
  });
  const selectLatestCalibration = createSelector(filterCalibrations, (filtered) => {
    return filtered.reduce(
      (r, c) => (c.createdAt > r?.createdAt) ? c : r, filtered[0]
    );
  });
  const selectLatestCalibrationTimestamp = createSelector(selectLatestCalibration, (latest) => {
    return (!latest) ? null : (latest.couplerCalibrations || [])
        .concat(latest.qubitCalibrations)
        .map(d => d.calibratedAt)
        .reduce((r, d) => d > r ? d : r, null);
  });
  const calibratedAt = useSelector(selectLatestCalibrationTimestamp);

  if (!controlServer) {
    return null;
  }

  const className = `control-server-display ${props.className ?? ""}`
    + ` commands-${controlServer.commandStatus}`
    + ` ${(controlServer.availableAt) ? "": "un"}available`
    + ` ${(controlServer.lockedAt) ? "": "un"}locked`
    + ` ${(controlServer.reservations?.find(r => r.active)) ? "" : "un"}reserved`
  ;

  return (
    <div className={className}>
      {/* <SvgIcon name={`${(controlServer.availableAt) ? "on": "off"}line`} className="availability"/> */}
      <SvgIcon name="qubits" className="availability"/>

      <div className="control-server-identifier identifier">
        {controlServer.identifier}
      </div>

      {props.showSystem && (
        <div className="control-server-system">
          {Aqumen.Utility.systemIdentifier(controlServer.session)}
        </div>
      )}

      {Boolean(controlServer.availableAt) && (
        <div className="control-server-job-queue">
          <span className="data-label">Queue Depth:</span>
          <span className="data">{controlServer.jobQueue?.queued.length}</span>
        </div>
      )}

      {Boolean(controlServer.availableAt) && (
        <div className="control-server-commands-status">
          <span className="data-label">Commands:</span>
          <span className="data-value">{_.startCase(controlServer.commandStatus)}</span>
        </div>
      )}

      <div className="control-server-availability">
        {Boolean(controlServer.availableAt) && (
          <span>
            <span className="data-label">Available:</span>
            <TimestampDisplay className="control-server-available-at" value={controlServer.availableAt}/>
          </span>
        )}
        {!Boolean(controlServer.availableAt) && (
          <span className="control-server-unavailable">Unavailable</span>
        )}
      </div>
      {Boolean(calibratedAt) && (
        <span>
          <span className="data-label">Calibrated:</span>
          <TimestampDisplay className="control-server-calibrated-at" value={calibratedAt}/>
        </span>
      )}
      {Boolean(controlServer.lockedAt) && (
        <div className="control-server-lock-status">
          <span className="data-label">Locked:</span>
          <TimestampDisplay className="control-server-locked-at" value={controlServer.lockedAt}/>
        </div>
      )}

      {Boolean(controlServer.reservations.length) && (
        <div className="control-server-reservations">
          <div className="control-server-reservations-title data-label">
            Reservations
          </div>
          <ul className="control-server-reservations-list">
            {
              _.orderBy("activateAt", "desc", controlServer.reservations).map((r) => {
                const open = (r.activateAt > Date.now() && !r.rejectedAt);
                return (
                  <li key={r.id} className={`reservation-line ${open ? "open" : ""} ${r.active ? "active" : ""}`}>
                    <div className="reservation-timestamp">
                      <TimestampDisplay className="reservation-activate-at" value={r.activateAt}/>
                    </div>
                    <div className="reservation-user">
                      - <UserDisplay className="reservation-recipient" value={r.recipient}/>
                    </div>
                  </li>
                );
              })
            }
          </ul>
        </div>
      )}
    </div>
  );
}
