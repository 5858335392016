import Aqumen from "@aqumen/sdk";
import React from "react";
import { TimestampDisplay } from "./timestamp_display.jsx";

import {friendlyJobStatus} from "./friendly_job_status.js";

export function JobStatusDisplay(props) {
  if (!props.job) {
    return null;
  }
  const className = `job-status-display ${props.className ?? ""}`;

  return (
    <div className={className}>
      <div className="job-status-label">
        {friendlyJobStatus(props.job).toLocaleLowerCase()}
        {props.job.exitedAt && (
          <TimestampDisplay className="job-status-timestamp" value={props.job.exitedAt} format="LT"/>
        )}
      </div>
      {props.job.status === "executing" && props.job.percentCompleted && (
        <div className="job percent-complete">{props.job.percentCompleted}%</div>
      )}
      {props.job.status === "queued" || props.job.status === "blocked" && (
        <div className="job queue-position">{Aqumen.Utility.ordinal(props.job.queuePosition + 1)}</div>
      )}
    </div>
  )
}
