import React from "react";

import {CalibrationIdentifierDisplay} from "../../display/calibration_identifier_display.jsx";
import {PercentDisplay} from "../../display/percent_display.jsx";
import {TimestampDisplay} from "../../display/timestamp_display.jsx";

export function CouplersTable(props) {
  if (!props.calibration) {
    return null;
  }

  return (
    <table className={"calibration-table table couplers " + (props.className ?? "")}>
      <thead>
        <tr>
          <th className="identifier">Coupler</th><th className="epg">SWAP EPG</th><th className="epg">eSWAP EPG</th><th className="timestamp">Measured</th>
        </tr>
      </thead>
      <tbody>
        {props.couplers.map((coupler) => (
          <tr className="calibration-row row" key={props.calibration.id + coupler.identifier}>
            <td className="identifier"><CalibrationIdentifierDisplay value={coupler.identifier}/></td>
            <td className="epg"><PercentDisplay value={coupler.swapEpg} scale={1}/></td>
            <td className="epg"><PercentDisplay value={coupler.eSwapEpg} scale={1}/></td>
            <td className="timestamp"><TimestampDisplay value={coupler.calibratedAt}/></td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
