import React from "react";

import {friendlyJobStatus} from "./friendly_job_status.js";
import {jobLastStatusChange} from "./job_last_status_change.js";
import {TimestampDisplay} from "./timestamp_display.jsx";

export function JobStatusDetailDisplay(props) {
  if (!props.job) {
    return null;
  }

  const label = friendlyJobStatus(props.job);
  const lastChange = jobLastStatusChange(props.job);

  const className = `job-status-detail-display ${_.kebabCase(props.job.status)} ${props.className ?? ""}`;

  if (props.job.exitedAt) {
    return (
      <div className={className}>
        <span className="data-label">{label}:</span>
        <TimestampDisplay className="job-last-status-change" value={lastChange}/>
      </div>
    );
  }

  return (
    <div className={className}>
      <span className="jobs-label">{label}…</span>
    </div>
  );
}
