import _ from "lodash/fp";
import {ButtonCopy} from "@minimum/utility";
import React, {useRef, useState} from "react";

import {ButtonDownloadArtifact} from "../widget/button_download_artifact.jsx";
import {ButtonSignal} from "../widget/button_signal.jsx";
import {ErrorsList} from "../list/errors_list.jsx";
import {friendlyJobType} from "./friendly_job_type.js";
import {JobReceiptDisplay} from "./job_creator_display.jsx";
import {JobStatusDisplay} from "./job_status_display.jsx";
import {JobStatusDetailDisplay} from "./job_status_detail_display.jsx";
import {SvgIcon} from "../utility/svg_icon.jsx";

export function JobDisplay(props) {
  const job = props.value;
  if (!job) {
    return <div className="job display empty"></div>;
  }

  const [signalling, setSignalling] = useState(false);
  const [copied, setCopied] = useState(false);
  const copiedTimeout = useRef(null);

  let className = `job-display ${job.type} ${job.status} ${props.className ?? ""}`;
  if (job.percentCompleted > 0 && job.percentCompleted < 100) {
    className += " has-percent-complete";
  }
  if (job.exitedAt) {
    className += " exited";
  }

  let controlServerClassName = "control-server";
  if (job.controlServer) {
    controlServerClassName += ` ${(job.controlServer.availableAt) ? "" : "un"}available`
      + ` ${(job.controlServer.lockedAt) ? "" : "un"}locked`;

    const ar = job.controlServer.reservations?.find(r => r.active);
    if (ar && ar.recipient.id !== job.createdBy.id) {
      controlServerClassName += " reservation-conflict";
    }
  }

  const copyId = () => {
    clearTimeout(copiedTimeout.current);
    navigator.clipboard.writeText(job.id);
    setCopied(true);
    copiedTimeout.current = setTimeout(() => setCopied(false), 1000);
  }

  return (
    <div className={className}>
      <div className="job-metadata">
        <SvgIcon name={(/simulate/i.test(job.type)) ? "simulate" : job.type}/>

        <div className="job-type">{friendlyJobType(job)}:</div>
        <div className="job-name">{job.name}</div>
        <JobStatusDisplay job={job}/>
        <div className={`job-id ${(copied) ? "copied" : ""}`} onClick={copyId}>
          <span className="job-id-text">{job.id}</span>
          <SvgIcon name="copy" className="copy-job-id"/>
          <SvgIcon name="copied" className="copied-job-id"/>
        </div>
      </div>

      <JobReceiptDisplay job={job}/>
      <JobStatusDetailDisplay job={job}/>

      {Boolean(job.controlServer) && (
        <div className={controlServerClassName}>
          <div className="control-server-identifier">
            <span className="data-label">Control Server:</span>
            <span className="data-value">{job.controlServer.identifier}</span>
          </div>
        </div>
      )}

      <ErrorsList/>

      <div className="job-actions">
        <ButtonCopy
          className="job-action copy-job-metadata"
          text={JSON.stringify(job, null, 2)}
        >
          <SvgIcon name="copy" className="copy-job-metadata"/>
          JSON
        </ButtonCopy>

        <ButtonDownloadArtifact className="job-action log" job={job} type="log">
          Log
        </ButtonDownloadArtifact>

        <ButtonDownloadArtifact className="job-action result" job={job} type="result">
          Result
        </ButtonDownloadArtifact>

        <ButtonSignal
          className="job-action interrupt"
          disabled={job.exitedAt || signalling}
          signal="interrupt"
          job={job}
          onClick={() => setSignalling(true)}
        >
          <SvgIcon name="interrupt"/>
          <span className="button-text">Interrupt</span>
        </ButtonSignal>

        <ButtonSignal
          className="job-action terminate"
          disabled={job.exitedAt || signalling}
          signal="terminate"
          job={job}
          onClick={() => setSignalling(true)}
        >
          <SvgIcon name="terminate"/>
          <span className="button-text">Terminate</span>
        </ButtonSignal>

      </div>
    </div>
  );
}
