import Aqumen from "@aqumen/sdk";
import {ButtonConfirmable} from "@minimum/utility";
import React, {useContext, useState} from "react";
import {useDispatch} from "react-redux";

import {ConnectionsContext} from "../../context/connections_context.js";
import {deauthenticate} from "../../slice/sessions_slice.js";

export function SystemAuthenticated(props) {
  console.group("SystemAuthenticated");
  try {
    console.debug("props", props);
    const dispatch = useDispatch();
    const {connections, setConnections} = useContext(ConnectionsContext);

    const session = props.value;
    if (!session) {
      console.warn("no session found in props.value", e);
      return null;
    }
    const systemIdentifier = Aqumen.Utility.systemIdentifier(session);
    const [signingOut, setSigningOut] = useState(false);

    const handleSignOut = async () => {
      console.group("SystemAuthenticated.handleSignOut");
      try {
        setSigningOut(true);
        connections[session.url]?.close();
        setConnections(_.omit([session.url], connections));
        dispatch(deauthenticate(session));
        await Aqumen.Session.destroy(session);
      } finally {
        setSigningOut(false);
        console.groupEnd();
      }
    }

    return (
      <div className={"system-authenticated " + (props.className ?? "")}>
        <div className="system-identifier">
          {systemIdentifier}
        </div>

        <div className="user-identifier">
          {session.user.identifier}
        </div>

        <div className="system-actions">
          <ButtonConfirmable
            aria-label={`Sign out of ${systemIdentifier}`}
            className="sign-out"
            onClick={handleSignOut}
            type="button"
            disabled={signingOut}
          >
            Sign Out
          </ButtonConfirmable>
        </div>
      </div>
    );
  } finally {
    console.groupEnd();
  }
}
