import _ from "lodash/fp";
import Aqumen from "@aqumen/sdk";
import {createSlice} from "@reduxjs/toolkit";

import {systemKey} from "./system_key.js";

const slice = createSlice({
  name: "sessions",
  initialState: {},
  reducers: {
    "authenticate": (state, action) => {
      state[action.payload.url] = action.payload;
    },
    "deauthenticate": (state, action) => _.omit(action.payload.url, state)
  }
});

export const {authenticate, deauthenticate} = slice.actions;
export const {reducer} = slice;
