import _ from "lodash/fp";
import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
  name: "Calibration",
  initialState: {},
  reducers: {
    "replace": (state, action) => {
      state[action.session.url] = _.keyBy("id", action.payload)
    },
    "update": (state, action) => {
      state[action.session.url] ||= {};
      state[action.session.url][action.payload.id] = action.payload;
    }
  },
  extraReducers: {
    "sessions/deauthenticate": (state, action) => _.omit(action.payload.url, state),
    "ControlServer/replace": (state, action) => {
      action.payload.forEach(controlServer => controlServer.calibrations.forEach(a => {
        state[action.session.url] ||= {};
        state[action.session.url][a.id] = Object.assign(
          {}, a, {controlServer: _.pick(["id", "session.url"], controlServer)}
        );
      }));
    }
  }
});

export const {reducer} = slice;
