import _ from "lodash/fp";

export function friendlyJobStatus(job) {
  switch (job.exit ?? job.status) {
    case "normal": return "Completed";
    case "executing": {
      switch (job.type) {
        case "compile": return "Compiling";
        case "simulate": "Simulating";
        case "run": return "Running";
        default: return "Unknown";
      }
    }

    default: return _.startCase(job.status);
  }
}
