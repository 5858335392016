import React from "react";

import activity from "../../assets/icons/activity.svg";
import compile from "../../assets/icons/compile.svg";
import copied from "../../assets/icons/copied.svg";
import copy from "../../assets/icons/copy.svg";
import download from "../../assets/icons/download.svg";
import interrupt from "../../assets/icons/interrupt.svg";
import offline from "../../assets/icons/offline.svg";
import online from "../../assets/icons/online.svg";
import qubits from "../../assets/icons/qubits.svg";
import run from "../../assets/icons/run.svg";
import settings from "../../assets/icons/settings.svg";
import simulate from "../../assets/icons/simulate.svg";
import terminate from "../../assets/icons/terminate.svg";

const icons = {
  activity,
  compile,
  copied,
  copy,
  download,
  interrupt,
  offline,
  online,
  qubits,
  run,
  settings,
  simulate,
  terminate
};

export function SvgIcon(props) {
  const className = `svg-icon ${props.name} ${props.className ?? ""}`;

  return (
    <span className={className} dangerouslySetInnerHTML={{__html: icons[props.name]}}></span>
  )
}
