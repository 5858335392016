import _ from "lodash/fp";
import {createSlice} from '@reduxjs/toolkit'

const slice = createSlice({
  name: "Reservation",
  initialState: {},
  reducers: {
    "replace": (state, action) => {
      state[action.session.url] = _.keyBy("id", action.payload)
    },
    "update": (state, action) => {
      state[action.session.url] ||= {};
      state[action.session.url][action.payload.id] = action.payload;
    }
  },
  extraReducers: {
    "sessions/deauthenticate": (state, action) => _.omit(action.payload.url, state)
  }
});

export const {reducer} = slice;
