import _ from "lodash/fp";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {ControlServersSidebar} from "../../list/control_servers_sidebar.jsx";
import {ErrorBoundary} from "../../error_boundary.jsx";
import {fetchAndReplaceAll} from "../../requests/fetch_and_replace_all.js";
import {JobsList} from "./jobs_list.jsx";

export function StatusPane(props) {
  const dispatch = useDispatch();
  const sessions = useSelector((s) => s.sessions);

  useEffect(() => fetchAndReplaceAll(dispatch, sessions), []);

  if (_.isEmpty(sessions)) {
    return null;
  }

  return (
    <ErrorBoundary>
      <div className={"status-pane pane " + (props.className ?? "")}>
        <div className="section-header">Control Servers</div>
        <ControlServersSidebar/>
        <ErrorBoundary>
          <div className="section-header">Aqümen Jobs</div>
          <JobsList/>
        </ErrorBoundary>
      </div>
    </ErrorBoundary>
  );
}
