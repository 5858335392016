import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {CalibrationsPane} from "./pane/calibrations/calibrations_pane.jsx";
import {ConnectionsContext} from "./context/connections_context.js";
import {ErrorBoundary} from "./error_boundary.jsx";
import {SessionsPane} from "./pane/sessions/sessions_pane.jsx";
import {StatusPane} from "./pane/status/status_pane.jsx";
import {SvgIcon} from "./utility/svg_icon.jsx";
import {TabbedItem, TabbedView} from "@minimum/tabbed";
import {websocket} from "./websocket.js";

export function App() {
  const dispatch = useDispatch();
  const sessions = useSelector(s => Object.values(s.sessions));
  const [connections, setConnections] = useState({});

  useEffect(() => {
    if (_.isEmpty(connections) && !_.isEmpty(sessions)) {
      let c = {};
      sessions.forEach(async s => c[s.url] = await websocket(dispatch, s));
      setConnections(c);
    }
  }, []);

  return (
    <div className="app">
      <ErrorBoundary>
        <ConnectionsContext.Provider value={{connections, setConnections}}>
          <TabbedView default="sessions" view="app">
            <TabbedItem name="jobs" icon={<SvgIcon name="activity"/>} content={<StatusPane/>}/>
            <TabbedItem name="calibrations" icon={<SvgIcon name="qubits"/>} content={<CalibrationsPane/>}/>
            <TabbedItem name="sessions" icon={<SvgIcon name="settings"/>} content={<SessionsPane/>}/>
          </TabbedView>
        </ConnectionsContext.Provider>
      </ErrorBoundary>
    </div>
  );
}
