import Aqumen from "@aqumen/sdk";
import React, {useContext, useState} from "react";
import {useDispatch} from "react-redux";
import {ButtonConfirmable} from "@minimum/utility";
import {changeHandler} from "@minimum/utility";
import {InputValidatable} from "@minimum/utility";
import {submitStopper} from "@minimum/utility";

import {authenticate} from "../../slice/sessions_slice.js";
import {ConnectionsContext} from "../../context/connections_context.js";
import {remove} from "../../slice/systems_slice.js";
import {websocket} from "../../websocket.js";

export function SystemUnauthenticated(props) {
  console.group("SystemUnauthenticated");
  try {
    console.debug("props", props);

    const dispatch = useDispatch();
    const emptyUser = {identifier: "", credential: ""};
    const [user, setUser] = useState(emptyUser);
    const [signInError, setSignInError] = useState(null);
    const [signingIn, setSigningIn] = useState(false);
    const {connections, setConnections} = useContext(ConnectionsContext);

    const system = props.value;
    if (!system) {
      console.warn("no system found at props.value", e);
      return null;
    }

    const handleChange = changeHandler(setUser, user);
    const handleRemove = () => dispatch(remove(system));
    const handleSubmit = submitStopper(async () => {
      console.group("SystemUnauthenticated");
      try {
        setSigningIn(true);
        console.debug("submit", {system, user});
        const session = await Aqumen.Session.create(
          system.url, system.key, user.identifier, user.credential
        );
        dispatch(authenticate(session));
        setConnections(Object.assign({}, connections, {[system.url]: await websocket(dispatch, session)}));
        setUser(emptyUser);
      } catch (e) {
        console.error("submit", e);
        setSignInError(
          (e.code === "401")
          ? "This username or passphrase is incorrect."
          : e.toString()
        );
      } finally {
        setSigningIn(false);
        console.groupEnd();
      }
    });

    const systemIdentifier = Aqumen.Utility.systemIdentifier(system);

    return (
      <div className={"system-unauthenticated " + (props.className ?? "")}>
        <div className="system-identifier">
          {systemIdentifier}
        </div>

        <div className="system-authenticate">
          <form className="system-unauthenticated-form" onSubmit={handleSubmit}>

            <InputValidatable name="identifier"
              aria-label={`Username for ${systemIdentifier}`}
              className="input-identifier"
              inputMode="email"
              invalidMessage="Enter your username…"
              onChange={handleChange}
              placeholder="jane@doe.example"
              required
              disabled={signingIn}
              type="text"
              value={user.identifier}
            />

            <InputValidatable name="credential"
              aria-label={`Passphrase for ${systemIdentifier}`}
              className="input-credential"
              invalidMessage="Enter your passphrase…"
              onChange={handleChange}
              required
              disabled={signingIn}
              type="password"
              value={user.credential}
            />

            <button
              aria-label={`Sign in to ${systemIdentifier}`}
              className="sign-in"
              type="submit"
              disabled={signingIn}
            >
              Sign In
            </button>

          </form>

          <div className="authenticate-error">
            {signInError && (
              <div className="message" role="alert">
                {signInError}
              </div>
            )}
          </div>
        </div>

        <div className="system-actions">
          <ButtonConfirmable
            aria-label={`Remove ${systemIdentifier}`}
            className="remove"
            onClick={handleRemove}
            type="button"
            disabled={signingIn}
          >
            Remove
          </ButtonConfirmable>
        </div>
      </div>
    );
  } finally {
    console.groupEnd();
  }
}
