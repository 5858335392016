import Aqumen from "@aqumen/sdk";
import React from "react";

import {SvgIcon} from "../utility/svg_icon.jsx";

export function ButtonDownloadArtifact(props) {
  let artifactType = props.type;
  if (artifactType === "result") {
    artifactType = (props.job.type === "compile") ? "jmz" : "resultJson";
  }
  const findArtifact = (j) => j.artifacts?.find(a => a.type === artifactType);

  const disabled = !Boolean(findArtifact(props.job))

  const handleClick = async () => {
    const latest = await Aqumen.Job.all(
      props.job.session,
      {artifacts: {id: true, type: true, url: true}},
      {jobs: {id: props.job.id}}
    );
    const artifact = findArtifact(latest?.[0]);
    if (artifact?.url) {
      const link = document.createElement('a');
      link.href = artifact.url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("artifact missing");
    }
  };

  const className = `download artifact ${props.type} ${props.className ?? ""}`;

  return (
    <button {...props} disabled={disabled} onClick={handleClick} className={className}>
      <SvgIcon name="download"/>
      <span className="button-text">{props.children}</span>
    </button>
  )
}
