import Aqumen from "@aqumen/sdk";

import {controlServersRequest} from "./control_servers_request.js";
import {jobQueuesRequest} from "./job_queues_request.js";
import {jobsRequest} from "./jobs_request.js";
import {reservationsRequest} from "./reservations_request.js";
import {replace} from "../slice/replace.js";

export function fetchAndReplaceAll(dispatch, sessions) {
  Object.keys(sessions).forEach((key) => {
    (async () => dispatch(replace(sessions[key], await Aqumen.ControlServer.all(sessions[key], ...controlServersRequest))))();
    (async () => dispatch(replace(sessions[key], await Aqumen.Job.all(sessions[key], ...jobsRequest))))();
    (async () => dispatch(replace(sessions[key], await Aqumen.Queue.all(sessions[key], ...jobQueuesRequest))))();
    (async () => dispatch(replace(sessions[key], await Aqumen.Reservation.all(sessions[key], ...reservationsRequest))))();
    (async () => dispatch(replace(sessions[key], await Aqumen.User.all(sessions[key]))))();
  });
}
