import React from "react";

import {TimestampDisplay} from "./timestamp_display.jsx";
import {UserDisplay} from "./user_display.jsx";

export function JobReceiptDisplay(props) {
  if (!props.job) {
    return null;
  }

  const className = `job-creator-display ${props.className ?? ""}`;

  return (
    <div className={className}>
      <UserDisplay className="job created-by" value={props.job.createdBy}/>
      <div className="received-at">
        <span className="data-label">Received:</span> <TimestampDisplay value={props.job.receivedAt}/>
      </div>
    </div>
  );
}
