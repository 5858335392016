import React, {useContext} from "react";
import {useSelector} from "react-redux";

import {SystemNew} from "./system_new.jsx";
import {SystemAuthenticated} from "./system_authenticated.jsx";
import {SystemUnauthenticated} from "./system_unauthenticated.jsx";

export function SessionsPane(props) {
  console.group("SessionsPane");
  try {
    const sessions = useSelector(s => s.sessions);
    const systems = useSelector(s => s.systems);

    console.debug("sessions", sessions);
    console.debug("systems", systems);

    return (
      <div className={"sessions-pane pane " + (props.className ?? "")}>

        {!_.isEmpty(sessions) && (
          <h1 className="sessions-header">Sessions</h1>
        )}
        {!_.isEmpty(sessions) && (
          <ul className="systems-list authenticated">
            {Object.keys(sessions).map((key, i) => (
              <li className="system-item" key={key}>
                {(i > 0) && (
                  <hr className="system-divider"/>
                )}
                <SystemAuthenticated value={sessions[key]}/>
              </li>
            ))}
          </ul>
        )}

        {!_.isEmpty(systems) && (
          <h1 className="sessions-header">Systems</h1>
        )}
        {!_.isEmpty(systems) && (
          <ul className="systems-list unauthenticated">
            {Object.keys(systems).filter(key => !sessions[key]).map((key, i) => (
              <li className="system-item" key={key}>
                {(i > 0) && (
                  <hr className="system-divider"/>
                )}
                <SystemUnauthenticated value={systems[key]}/>
              </li>
            ))}
          </ul>
        )}

        <h1 className="sessions-header" id="system-new-description">
          Add a System
        </h1>
        <SystemNew/>
      </div>
    );
  } finally {
    console.groupEnd();
  }
}
