import React from "react";

export function ErrorsList(props) {
  const errors = props.value;
  if (!errors || errors.length === 0) {
    return null;
  }
  const key = error.extensions?.id || hash(error.toString());
  return (
    <ol className={"errors list " + (props.className ?? "")}>
      {errors.map((error) => (
        <li className="error" key={key}>
          {error.message}
        </li>
      ))}
    </ol>
  );
}
