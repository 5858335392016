import dayjs from "dayjs";
import React from "react";

export function TimestampDisplay(props) {
  const timestamp = props.value;

  let className = `timestamp-display ${props.className ?? ""}`
  let formatted = null;
  if (timestamp) {
    formatted = dayjs(timestamp).format(props.format ?? "LT ddd MMM D");
  } else {
    className += " empty";
  }

  return (
    <span {...props} className={className}>{formatted}</span>
  );
}
