import _ from "lodash/fp";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {CalibrationPanel} from "./calibration_panel.jsx";
import {ControlServersSidebar} from "../../list/control_servers_sidebar.jsx";
import {ErrorBoundary} from "../../error_boundary.jsx";
import {fetchAndReplaceAll} from "../../requests/fetch_and_replace_all.js";

export function CalibrationsPane(props) {
  const dispatch = useDispatch();
  const sessions = useSelector((s) => s.sessions);

  useEffect(() => fetchAndReplaceAll(dispatch, sessions), []);

  // if (_.isEmpty(sessions)) {
  //   return null;
  // }

  return (
    <ErrorBoundary>
      <div className={"calibrations-pane pane " + (props.className ?? "")}>
        <div className="section-header">Control Servers</div>
        <ControlServersSidebar/>
        <div className="section-header">Calibrations</div>
        <ErrorBoundary>
          <CalibrationPanel/>
        </ErrorBoundary>
      </div>
    </ErrorBoundary>
  );
}
