import _ from "lodash/fp";
import React from "react";
import {useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {JobDisplay} from "../../display/job_display.jsx";
import {flatSelector} from "../../utility/flat_selector.js";

export function JobsList(props) {
  const showSystem = useSelector(s => Object.keys(s.sessions).length > 1);

  const selectArtifacts = flatSelector("artifacts");
  const selectControlServers = flatSelector("controlServers");
  const selectJobs = flatSelector("jobs");
  const selectQueues = flatSelector("queues");
  const selectUsers = flatSelector("users");

  const selectFilter = createSelector(s => s.filters?.jobs, f => f);

  const filterJobs = createSelector(selectJobs, selectFilter, (all, filter) => {
    if (!filter?.controlServer?.id) {
      return all;
    }

    return all.filter((j) => {
      return j.controlServer?.id === filter.controlServer.id
        // TODO single-level pipe check only as QCI does not YET use complex pipes
        || all.find((j2) => {
          return j2.pipeJob?.id === j.id && j2.controlServer?.id === filter.controlServer.id;
        });
    });
  });
  const orderJobs = createSelector(filterJobs, _.orderBy("receivedAt", "desc"));
  const jobs = useSelector(createSelector(
    selectArtifacts,
    selectControlServers,
    selectQueues,
    selectUsers,
    orderJobs,
    (allArtifacts, controlServers, queues, users, jobs) => {
      return jobs.map((job) => {
        let queuePosition = -1;
        if (!job.exitedAt) {
          queues.every((queue) => {
            queuePosition = queue.queued.findIndex(q => q.id === job.id);
            return (queuePosition === -1);
          });
        }
        const artifacts = allArtifacts.filter(a => a.job.id === job.id);
        const controlServer = (job.controlServer?.id) ? controlServers.find(c => c.id === job.controlServer.id) : null;
        const createdBy = users.find(u => u.id === job.createdBy.id);
        return Object.assign({}, job, {artifacts, controlServer, createdBy, queuePosition});
      });
    }
  ));

  if (jobs.length === 0) {
    return (
      <ol className="jobs-list list empty"></ol>
    );
  }

  return (
    <ul className={"jobs-list list " + (props.className ?? "")}>
      {jobs.map((job) => (
        <li className="jobs-item item" key={job.id}>
          <JobDisplay value={job} showSystem={showSystem}/>
        </li>
      ))}
    </ul>
  );
}
