import _ from "lodash/fp";
import {createSlice} from "@reduxjs/toolkit";

import {systems} from "../systems.js";

const slice = createSlice({
  name: "Systems",
  initialState: systems,
  reducers: {
    "add": (state, {payload: {url, key}}) => {
      state[url] = {url, key};
    },
    "remove": (state, {payload}) => _.omit(payload.url, state)
  }
});

export const {add, remove} = slice.actions;
export const {reducer} = slice;
