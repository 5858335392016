import _ from "lodash/fp";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import {ControlServersSidebarTile} from "./control_servers_sidebar_tile.jsx";
import {set as setFilter, clear as clearFilter} from "../slice/filters_slice.js";
import {flatSelector} from "../utility/flat_selector.js";

export function ControlServersSidebar(props) {
  const dispatch = useDispatch();
  const filters = useSelector(s => s.filters);
  const showSystem = useSelector(s => Object.keys(s.sessions).length > 1);

  const selectControlServers = flatSelector("controlServers");
  const selectJobQueues = flatSelector("queues");
  const selectReservations = flatSelector("reservations");
  const selectUsers = flatSelector("users");

  const selectMerged = createSelector(
    selectControlServers, selectJobQueues, selectReservations, selectUsers, (cs, jq, rs, us) => {
      return cs.map(c => Object.assign({}, c, {
        jobQueue: jq.find(q => q.controlServer?.id === c.id),
        lockedBy: (c.lockedBy?.id) ? us.find(u => c.lockedBy.id) : null,
        reservations: rs.filter(r => r.controlServer?.id === r.id).map(r => {
          return Object.assign({}, r, {recipient: us.find(u => u.id === r.recipient.id)})
        })
      }));
    }
  );
  const selectOrdered = createSelector(
    selectMerged, _.orderBy([s => Boolean(s.availableAt), "identifier"], ["desc", "asc"])
  );

  const controlServers = useSelector(selectOrdered);
  if (controlServers.length === 0) {
    return (
      <ol className="control-servers-list list empty"></ol>
    );
  }

  const handleItemClick = (controlServer) => () => {
    if (filters.jobs?.controlServer?.id === controlServer.id) {
      return dispatch(clearFilter({type: "jobs"}));
    }
    return dispatch(setFilter({type: "jobs", filter: {controlServer}}));
  };

  return (
    <ul className={"control-servers-list list " + (props.className ?? "")}>
      {controlServers.map((controlServer) => {
        let itemClassName = "control-servers-item item";
        if (filters.jobs?.controlServer?.id === controlServer.id) {
          itemClassName += " clicked";
        }
        return (
          <li className={itemClassName} key={controlServer.session.id + controlServer.id} onClick={handleItemClick(controlServer)}>
            <ControlServersSidebarTile value={controlServer} showSystem={showSystem}/>
          </li>
        );
      })}
    </ul>
  );
}
