import _ from "lodash/fp";
import React from "react";
import {useSelector} from "react-redux";
import {createSelector} from "@reduxjs/toolkit";

import qubitArrangement from "../../../assets/qubit_arrangement.svg";

import {CouplersTable} from "./couplers_table.jsx";
import {QubitsTable} from "./qubits_table.jsx";
import {flatSelector} from "../../utility/flat_selector.js";

export function CalibrationPanel() {
  const selectCalibrations = flatSelector("calibrations");
  const selectControlServers = flatSelector("controlServers");
  const selectFilter = createSelector(s => s.filters?.jobs, f => f);

  const findControlServer = createSelector(selectControlServers, selectFilter, (all, f) => {
    return all.find((cs) => {
      return cs.id === f?.controlServer?.id
        && cs.session.url === f?.controlServer?.session?.url;
    });
  });
  const filterCalibrations = createSelector(selectCalibrations, selectFilter, (all, f) => {
    return all.filter(c => c.controlServer.id === f?.controlServer?.id);
  });
  const latestCalibration = createSelector(filterCalibrations, (filtered) => {
    return filtered.reduce(
      (r, c) => (c.createdAt > r?.createdAt) ? c : r, filtered[0]
    );
  });
  const createOrdered = (prop) => {
    return createSelector(latestCalibration, (calibration) => {
      return _.orderBy("identifier", "asc", calibration?.[prop]);
    });
  };
  const orderCouplers = createOrdered("couplerCalibrations");
  const orderQubits = createOrdered("qubitCalibrations");

  const controlServer = useSelector(findControlServer);
  const calibration = useSelector(latestCalibration);
  const couplers = useSelector(orderCouplers);
  const qubits = useSelector(orderQubits);

  return (
    <div className="calibration-panel">
        {controlServer && (
          <div className="calibration-card">
            <div className="qubit-arrangement" dangerouslySetInnerHTML={{__html: qubitArrangement}}/>
            <div className="identifier">{controlServer.identifier}</div>
          </div>
        )}
        {calibration && (
          <div className="calibration-card">
            <QubitsTable {...{calibration, qubits}}/>
            <CouplersTable {...{calibration, couplers}}/>
          </div>
        )}
        {!calibration && controlServer && (
          <div className="calibration-card empty">
            No calibration data available for <span className="identifier">{controlServer.identifier}</span>.
          </div>
        )}

        {!calibration && !controlServer && (
          <div className="calibration-card empty">
            Select a control server to view qubit arrangement and calibration.
          </div>
        )}
    </div>
  );
}
