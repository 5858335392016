import React from "react";

import {CalibrationIdentifierDisplay} from "../../display/calibration_identifier_display.jsx";
import {PercentDisplay} from "../../display/percent_display.jsx";
import {TimestampDisplay} from "../../display/timestamp_display.jsx";

export function QubitsTable(props) {
  if (!props.calibration) {
    return null;
  }

  return (
    <table className={"calibration-table table qubits " + (props.className ?? "")}>
      <thead>
        <tr>
          <th className="identifier">Qubit</th><th className="epg">X EPG</th><th className="epg">Xo2 EPG</th><th className="timestamp">Measured</th>
        </tr>
      </thead>
      <tbody>
        {props.qubits.map((qubit) => (
          <tr className="calibration-row row" key={props.calibration.id + qubit.identifier}>
            <td className="identifier"><CalibrationIdentifierDisplay value={qubit.identifier}/></td>
            <td className="epg"><PercentDisplay value={qubit.xEpg} scale={1}/></td>
            <td className="epg"><PercentDisplay value={qubit.xo2Epg} scale={1}/></td>
            <td className="timestamp"><TimestampDisplay value={qubit.calibratedAt}/></td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
